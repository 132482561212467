@import 'variables';
@import 'media-queries';

.toast-container.feedback {
  .toast-icon {
    height: $icon-size;
    width: $icon-size;
    margin-right: 4px;
    background-size: $icon-size;
    background-repeat: no-repeat;
    display: inline-block;
    vertical-align: top;
  }

  .icon-column {
    width: 20px;
    padding: 5px 5px;
    margin-left: -15px;
    // margin-top: ;
  }

  .toast-content {
    display: flex;
  }

  .message-column {
    width: 260px;
    padding: 0 15px 0 5px;
  }

  .toast-message {
    line-height: 1.4;
    margin-top: 4px;
    margin-bottom: 6px;
  }

  &.success {
    border-color: $success-color;
    border-left-width: $space-xs;

    .left-border {
      border-color: $success-color;
    }

    .toast-icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADESURBVEhLrY3BEcMgDATdU1pJiekzCfIxscmCBGhn9gN3p2OG5+vx7qnIHjTsqWocGplRMz5UXlWTDBV21fQdCmao+TsUzFInTiiQbdqhyE7KMRsR9F91A5420EC54vDT08oNlKu6gaKFvtDbles/GT5UobdC2yPdoAUcqEeGwhbqQPme4YIFGyg3cqpkYUH/ntPF1UNFO1agz2x16oQCWerEDwplqPl/KLyrphkqrKrJMVScVVNxaMRT1XVotFXRDsfxAesEdLPtoGWDAAAAAElFTkSuQmCC');
    }
  }

  &.error {
    border-color: $error-color;
    border-left-width: $space-xs;

    .left-border {
      border-color: $error-color;
    }

    .toast-icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAbCAYAAACN1PRVAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADLSURBVEhLrY1BEsIwDMT6No58nUcBdneGNCi2k0YzujTy9qjyejzfkcruQcOZOq1DIzNqJoeOV9UkQwd31fQVCneo+SsU7lK/OKFgt/pV/rOo8YEv9NZaj0T0ZvTvrWlgetSQfR9ZikwPA+imtxyaHgPUklOx6QcN1IycOvAYoJYsxx4G0E1vKfSoIfs+Mo08aJh9b1VSi+jN1HO4YSrLwx3qVycU7FK/+EHRDjX/D8V31TRDB6tqMoYOZ9TMHDSUqdN1aLRX6YDj+ACvhaU7C5SWJgAAAABJRU5ErkJggg==');
    }
  }

  &.warning {
    border-color: $warning-color;
    border-left-width: $space-xs;

    .left-border {
      border-color: $warning-color;
    }

    .toast-icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAVCAYAAACzK0UYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACESURBVEhLzY3bDYAgEAT5sP9qrMomNMQhXsiCvC46yXzoLbvhlxz7dkb59MF9JA24DrmP5ANJzvOociuxOVSxldg4qlRJfIxSWel/N3mRLavdmlElUc7fjUQ5v6Me90hNHfWwR2rKqEdWYs05iXpgJTY+osIzUvugQiuk/kYFVki9NyFcdzIwFIQUphUAAAAASUVORK5CYII=');
    }
  }

  &.info {
    border-color: $info-color;
    border-left-width: $space-xs;

    .left-border {
      border-color: $info-color;
    }

    .toast-icon {
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB0AAAAdCAYAAABWk2cPAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAACxSURBVEhL7Y1RCsJQDATfdXpm7+V1aloXFDp9SZoICg7MV3Y346tZbvc1ouI1aDii6nloLKNmYtBARc2eQ6UONc9QoUPNH6Fwp3rzgkIzVbvc26HATNWuP6XjJ/0/dd2LBt08a0WDbp61okE3z1rRoJtnrWjQzbNWNOjmqWq+rNpvPFXtCQVmqlZ7ukGhTvXmCIU71DxDhQ41fw6VKmo2Bg1k1EweGouoeh0af1cxhzEeSq496qenLV4AAAAASUVORK5CYII=');
    }
  }
}
